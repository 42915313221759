<template>
  <Breadcrumb />
  <div class="row">
    <div class="card">
      <div class="card-body">
        <form class="form-pagelayouttemplate-edit pe-2 ps-2" novalidate>
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <div class="row row-cols-1">
            <div class="col col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "SystemMessageTemplates.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="model.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col col-md-6">
              <label for="IsActive" class="form-label">{{
                $t(
                  "SystemMessageTemplates.IsActive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="model.isActive"
                />
              </div>
            </div>
          </div>
          <div class="row row-cols-1 mt-2">
            <div class="col col-md-12">
              <label for="Layout" class="form-label">{{
                $t(
                  "PermissionGroups.PageLayout",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <textarea
                class="form-control"
                id="Layout"
                v-model="model.layout"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                rows="3"
              ></textarea>
            </div>
          </div>
          <ActionButtons
            classes="mt-3"
            @methodSave="onSubmit('btn-save')"
            :isNewRecord="false"
          />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "PageLayoutTemplateEdit",
  components: {},
  data() {
    return {
      errors: [],
      model: {},
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
    };
  },
  methods: {
    getPageLayoutTemplateData() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-PageLayoutTemplateGet?id={0}",
            this.$route.params.pageLayoutTemplateId
          )
        )
        .then((response) => {
          this.model = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-pagelayouttemplate-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-PageLayoutTemplateUpdate", { ...this.model })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/PageLayoutTemplate/List/");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
  mounted() {
    this.getPageLayoutTemplateData();
  },
};
</script>
